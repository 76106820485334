import { CreateActionParams } from '../actions';
import { bookingsBookingsFormLoad } from '@wix/bi-logger-wixboost-ugc/v2';
import { FormStatus } from '../../../../types/form-state';
import { ReferralInfo } from '../../../../types/biLoggerTypes';
import { FormState } from '../../../../utils/state/types';
import { createInitialState } from '../../../../utils/state/initialStateFactory';
import { AdditionalTimeSlots } from '../../../../tpa-plugins/additional-time-slots/controller';
import { ExperimentsConsts } from '../../../../consts/experiments';

export type InitializeWidget = () => void;

export function createInitializeWidgetAction({
  flowApi,
  controllerConfig,
  getControllerState,
  internalActions: { calculatePaymentDetails, errorHandlers },
  context: {
    biLogger,
    formApi,
    user,
    reportError,
    wixSdkAdapter,
    t,
    bookingsDataCapsule,
    experiments,
  },
}: CreateActionParams): InitializeWidget {
  return async () => {
    const [state, setState] = getControllerState();

    try {
      const initialState: FormState = await createInitialState({
        currentState: state,
        wixSdkAdapter,
        bookingsDataCapsule,
        flowApi,
        formApi,
        t,
        reportError,
      });
      const { isCart, isServiceInCart, serviceData } = initialState;

      if (initialState?.errors?.length) {
        console.error(initialState.errors[0]);
      }

      setState({
        ...initialState,
      });

      biLogger?.initialDefaultBiLogger(initialState);

      biLogger?.report(
        bookingsBookingsFormLoad({
          formId: serviceData.formSchema.formId,
          ...(isCart
            ? {
                ...(isServiceInCart
                  ? { referralInfo: ReferralInfo.AddMoreSessions }
                  : {}),
                emptyCart: !isServiceInCart,
              }
            : {}),
        }),
      );

      if (
        experiments.enabled(ExperimentsConsts.AdditionalTimeSlotsInFormPlugin)
      ) {
        AdditionalTimeSlots.setAdditionalTimeSlotInput(
          initialState.formSelectedSlot,
          controllerConfig,
        );
      }

      setState({
        status: FormStatus.IDLE,
      });

      await calculatePaymentDetails({});
    } catch (e) {
      console.error(e);
      setState({
        status: FormStatus.IDLE,
      });
    }
  };
}
