import { IHttpClient } from '@wix/yoshi-flow-editor';
import { SlotService } from '../../../utils/state/types';
import { createGroupedBookings } from '@wix/ambassador-bookings-v2-booking/http';
import {
  Booking,
  ContactDetails,
  CreateGroupedBookingsRequest,
  CustomFormField,
} from '../../../types/ambassador/bookings/ambassador-bookings-v2-booking';
import { getServiceSlotIdentifier, mapToArray } from '../../../utils';
import { createBookingEntity } from './utils';

export const sendCreateGroupBookingsRequest = async (
  httpClient: IHttpClient,
  slotServices: SlotService[],
  contactDetails: ContactDetails,
  additionalFields: CustomFormField[],
  numberOfParticipants: number | undefined,
  sendSmsReminder: boolean | undefined,
  v2Availability: boolean | undefined,
) => {
  const bookings: Booking[] = mapToArray<SlotService>(slotServices).map(
    (slotService) => {
      return createBookingEntity({
        slotService,
        numberOfParticipants,
        additionalFields,
        contactDetails,
        v2Availability,
      });
    },
  );

  const createGroupedBookingsRequest: CreateGroupedBookingsRequest = {
    bookings,
    participantNotification: {
      notifyParticipants: true,
    },
    sendSmsReminder,
  };
  const createGroupBookingsResult = await httpClient.request(
    createGroupedBookings(createGroupedBookingsRequest),
  );

  const result: { [key: string]: string } = {};
  if (
    createGroupBookingsResult.data.bookings &&
    createGroupBookingsResult.data.bookings.length
  ) {
    slotServices.forEach((slotService: SlotService, index: number) => {
      result[getServiceSlotIdentifier(slotService.nestedSlot)] =
        createGroupBookingsResult.data.bookings![index].bookingId!;
    });
  }

  return result;
};
