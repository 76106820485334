import {
  ServiceData,
  SlotService,
  SlotServices,
} from '../../../../../utils/state/types';
import { Submission } from '@wix/forms-ui/types';
import { mapFormSubmission } from '../../../../../utils/mappers/form-submission.mapper';
import { CartFlow, CartModalStatus } from '../../../../../types/types';
import {
  BookingsQueryParams,
  WixOOISDKAdapter,
} from '@wix/bookings-adapter-ooi-wix-sdk';
import { CreateActionParams } from '../../actions';
import {
  bookingsUouBookFlowBookingFormClickNext,
  bookingsUouBookFlowBookingFormClickNextFailure,
  bookingsUouSessionAddedToCartModalInteractions,
} from '@wix/bi-logger-wixboost-ugc/v2';
import { BookErrorType, GenericErrorType } from '../../../../../types/errors';
import {
  CreateCheckoutResponse,
  CreateOrderResponse,
} from '@wix/ambassador-checkout/http';
import { FormStatus } from '../../../../../types/form-state';
import { SelectedVariants, ServiceType } from '@wix/bookings-uou-types';
import {
  CustomOption,
  DynamicPriceInfo,
} from '../../../../../types/dynamicPrice';
import { ServiceOptionType } from '@wix/ambassador-bookings-catalog-v1-service-options-and-variants/types';
import { ExperimentsConsts } from '../../../../../consts/experiments';
import { mapCartToBookingsLineItems } from '../../../../../utils/mappers/cart.mapper';
import {
  AddToCartResponse,
  Cart,
  UpdateCartRequest,
} from '@wix/ambassador-ecom-v1-cart/types';
import { FormApi } from '../../../../../api/FormApi';
import { getContactDetailsWithFieldIds } from '../../../../../utils/mappers/collapseForm.mapper';
import {
  CartModalInteractionActionName,
  FormClickNextAction,
} from '../../../../../types/biLoggerTypes';
import { Experiments } from '@wix/yoshi-flow-editor';
import { canCompleteBoooking } from '../../../../../utils/validations/canBook';
import { findOverlappingResource } from '../../../../../utils/cart-conflict/cartConflict';
import { getServiceSlotIdentifier, mapToArray } from '../../../../../utils';
import { GUID } from '@wix/bi-logger-wixboost-ugc/v2/types';
import { ContactDetails } from '../../../../../types/ambassador/bookings/ambassador-bookings-v2-booking';
import { FormBiLogger } from '../../../../../utils/bi/biLoggerFactory';

export async function handleSingleSessionFlow(
  {
    getControllerState,
    internalActions: { errorHandlers },
    context: { biLogger, wixSdkAdapter, formApi, reportError, experiments },
  }: CreateActionParams,
  submission: Submission,
  slotServices: SlotServices,
  cartFlow?: CartFlow,
  v2Availability?: boolean,
) {
  const [state, setState] = getControllerState();
  const {
    businessInfo,
    couponInfo,
    isBookingsOnEcom,
    isCart,
    isServiceInCart,
    collapseFormValues,
    isDayful,
    bookingsLineItemOptions,
    formSelectedSlot,
    serviceData,
  } = state;
  setState({
    status:
      cartFlow === CartFlow.ADD_MORE_SESSIONS
        ? FormStatus.PROCESSING_CART_BOOK_NOW_REQUEST
        : FormStatus.PROCESSING_BOOK_REQUEST,
  });

  if (isServiceInCart && isBookingsOnEcom) {
    submission = {
      ...submission,
      ...getContactDetailsWithFieldIds({
        form: getFormSchema(serviceData),
        contactDetails: collapseFormValues,
      }),
    };
  }

  if (isBookingsOnEcom) {
    if (
      !(await canCompleteBoooking({
        formApi,
        isDayful: isDayful!,
        wixSdkAdapter,
        serviceData,
        activeFeatures: businessInfo.activeFeatures!,
      }))
    ) {
      setState({ status: FormStatus.IDLE });
      return;
    }

    const isAddTimeZoneToContactDetailsEnabled = experiments.enabled(
      ExperimentsConsts.AddTimeZoneToContactDetails,
    );

    try {
      const {
        contactDetails,
        additionalFields,
        numberOfParticipants,
        sendSmsReminder,
      } = mapFormSubmission({
        submission,
        formSchema: serviceData.formSchema,
        businessInfo,
        collapseFormValues: isServiceInCart ? collapseFormValues : undefined,
        timezone: isAddTimeZoneToContactDetailsEnabled
          ? getTimezone(serviceData)
          : undefined,
      });

      if (
        isCart &&
        bookingsLineItemOptions &&
        isIndividualService(serviceData)
      ) {
        const overlappingResource = findOverlappingResource(
          formSelectedSlot.nestedSlots,
          bookingsLineItemOptions,
        );
        if (overlappingResource) {
          errorHandlers.addError(
            GenericErrorType.OVERLAPPING_SLOTS_ON_CART_ERROR,
            { staffMember: overlappingResource },
          );
          setState({ status: FormStatus.IDLE });
          return;
        }
      }

      const { createCheckoutResponse, bookingIds, addToCurrentCartResponse } =
        await formApi.checkoutBooking({
          serviceData,
          v2Availability,
          contactDetails,
          additionalFields,
          sendSmsReminder,
          appliedCoupon: couponInfo.appliedCoupon,
          ...(shouldTakeNumberOfParticipantsFromForm(serviceData)
            ? { numberOfParticipants }
            : {}),
          isCart,
          cartFlow,
          onError: (error) => {
            errorHandlers.addError(error, { numberOfParticipants });
            biLogger?.report(
              bookingsUouBookFlowBookingFormClickNextFailure({
                errorReason: error,
              }),
            );
          },
          country: businessInfo.countryCode!,
          areCouponsAvailable: couponInfo.areCouponsAvailable,
        });

      if (
        isCart &&
        addToCurrentCartResponse &&
        !addToCurrentCartResponse?.cart
      ) {
        setState({ status: FormStatus.IDLE });
        return;
      } else {
        if (
          createCheckoutResponse &&
          bookingIds &&
          !isSubmitValid(createCheckoutResponse, bookingIds)
        ) {
          setState({ status: FormStatus.IDLE });
          return;
        }

        await reportBiForBookingsUouBookFlowBookingFormClickNext(
          serviceData,
          sendSmsReminder,
          bookingIds,
          biLogger,
          isCart,
        );
      }

      const isDayfulOverrideEcomContinueShoppingUrlEnabled =
        experiments.enabled(
          ExperimentsConsts.DayfulOverrideEcomContinueShoppingUrl,
        );

      let continueShoppingUrl = '';

      if (isDayfulOverrideEcomContinueShoppingUrlEnabled) {
        const isFixWidgetsNavigationAfterCheckoutWasRemovedEnabled =
          experiments.enabled(
            ExperimentsConsts.FixWidgetsNavigationAfterCheckoutWasRemoved,
          );
        const queryParams = wixSdkAdapter.getUrlQueryParams();
        if (isDayful) {
          continueShoppingUrl =
            queryParams.origin ||
            `${await wixSdkAdapter.getBookOnlineFullUrl(
              isFixWidgetsNavigationAfterCheckoutWasRemovedEnabled,
            )}?dayful=${queryParams.dayful}`;
        }
      }

      if (addToCurrentCartResponse?.cart) {
        const lineItems = addToCurrentCartResponse?.cart?.lineItems || [];
        if (lineItems.length === 1) {
          await updateCartWithMemberDetails({
            addToCurrentCartResponse,
            contactDetails,
            formApi,
            country: businessInfo.countryCode!,
            experiments,
          });
        }
        if (
          wixSdkAdapter.getUrlQueryParamValue(
            BookingsQueryParams.CONTINUE_SHOPPING_URL,
          )
        ) {
          setState({
            cartModal: {
              status: CartModalStatus.OPEN,
              lineItems,
              biLoggerData: getBiLoggerData(
                serviceData,
                sendSmsReminder,
                bookingIds,
              ),
            },
            status: FormStatus.IDLE,
          });
          await wixSdkAdapter.reloadCart();
          biLogger?.report(
            bookingsUouSessionAddedToCartModalInteractions({
              ...getBiLoggerData(serviceData, sendSmsReminder, bookingIds),
              actionName: CartModalInteractionActionName.Load,
            }),
          );
        } else {
          await wixSdkAdapter.navigateAfterAddToCart();
        }
      } else {
        if (createCheckoutResponse && isOnlineFlow(createCheckoutResponse)) {
          return wixSdkAdapter.navigateToEcomCheckoutPage({
            checkoutId: createCheckoutResponse!.checkout!.id!,
            ...(continueShoppingUrl ? { continueShoppingUrl } : {}),
          });
        } else {
          return wixSdkAdapter.navigateToEcomThankYouPage({
            orderId: createCheckoutResponse!.orderId!,
            ...(continueShoppingUrl ? { continueShoppingUrl } : {}),
          });
        }
      }
    } catch (error) {
      errorHandlers.addError(error as BookErrorType);
      reportError(error as BookErrorType);
      setState({ status: FormStatus.IDLE });
      biLogger?.report(
        bookingsUouBookFlowBookingFormClickNextFailure({
          errorReason: error as Maybe<string>,
        }),
      );
    }
  } else {
    setState({ status: FormStatus.IDLE });
    return openUoUPremiumModal(wixSdkAdapter, serviceData);
  }
}

const openUoUPremiumModal = async (
  wixSdkAdapter: WixOOISDKAdapter,
  serviceData: ServiceData,
) => {
  const sharedService = mapToArray<SlotService>(serviceData.slotServices)[0];
  return wixSdkAdapter.openUoUPremiumModal(
    sharedService?.service.type,
    'bookings-form',
  );
};

const isSubmitValid = (
  createCheckoutResponse: CreateCheckoutResponse | CreateOrderResponse,
  bookingIds: { [key: string]: string },
) => {
  if (!Object.keys(bookingIds).length) {
    return false;
  }

  if (isOnlineFlow(createCheckoutResponse)) {
    return createCheckoutResponse.checkout?.id !== undefined;
  }

  return createCheckoutResponse?.orderId !== undefined;
};

const getDynamicPriceBIReportParams = (
  dynamicPriceInfo: DynamicPriceInfo,
): {
  price_type?: ServiceOptionType;
  numOfOptions?: number;
  dynamic_price_participants_json?: string;
} => {
  const dynamicPriceType = dynamicPriceInfo?.selectedPreferences?.[0].type;
  const isCustomType = dynamicPriceType === ServiceOptionType.CUSTOM;
  const dynamic_price_participants_json = isCustomType
    ? JSON.stringify(
        dynamicPriceInfo.selectedVariants?.map(
          (selectedVariant: SelectedVariants) => {
            const option = dynamicPriceInfo?.customOptions?.find(
              (customOption: CustomOption) =>
                customOption.optionId ===
                selectedVariant.choices?.[0]?.optionId,
            )?.options?.[0];
            return (
              option && {
                label: option?.title,
                amount: option?.price,
                num_participants: selectedVariant.numberOfParticipants,
              }
            );
          },
        ),
      )
    : '';
  return {
    price_type: dynamicPriceType,
    numOfOptions:
      dynamicPriceInfo?.serviceOptionsAndVariants?.options?.values?.length,
    dynamic_price_participants_json,
  };
};

const getBiLoggerData = (
  serviceData: ServiceData,
  sendSmsReminder: boolean,
  bookingIds: { [key: string]: string } | undefined,
) => {
  const sharedService = mapToArray<SlotService>(serviceData.slotServices)[0];
  let dynamicPriceBIReportParams;
  if (sharedService.dynamicPriceInfo?.serviceOptionsAndVariants) {
    dynamicPriceBIReportParams = getDynamicPriceBIReportParams(
      sharedService.dynamicPriceInfo!,
    );
  }
  return {
    smsNotificationRequest: sendSmsReminder,
    bookingId:
      bookingIds && Object.keys(bookingIds)?.length
        ? (bookingIds[getServiceSlotIdentifier(sharedService.nestedSlot)] as
            | GUID
            | undefined)
        : undefined,
    ...(dynamicPriceBIReportParams ? dynamicPriceBIReportParams : {}),
  };
};

async function reportBiForBookingsUouBookFlowBookingFormClickNext(
  serviceData: ServiceData,
  sendSmsReminder: boolean,
  bookingIds: { [key: string]: string } | undefined,
  biLogger: FormBiLogger | undefined,
  isCart: boolean | undefined,
) {
  await biLogger?.report(
    bookingsUouBookFlowBookingFormClickNext({
      ...getBiLoggerData(serviceData, sendSmsReminder, bookingIds),
      ...(isCart ? { action: FormClickNextAction.AddToCart } : {}),
    }),
  );
}

function isOnlineFlow(
  checkoutResponse: CreateCheckoutResponse | CreateOrderResponse,
): checkoutResponse is CreateCheckoutResponse {
  return (checkoutResponse as CreateCheckoutResponse)?.checkout !== undefined;
}

async function updateCartWithMemberDetails({
  addToCurrentCartResponse,
  contactDetails,
  formApi,
  country,
  experiments,
}: {
  addToCurrentCartResponse?: AddToCartResponse;
  contactDetails: ContactDetails;
  formApi: FormApi;
  country: string;
  experiments: Experiments;
}) {
  const cartLineItems = addToCurrentCartResponse?.cart?.lineItems || [];
  const bookingsLineItems = mapCartToBookingsLineItems(cartLineItems);
  const isFirstBookingItemOnCart = bookingsLineItems.length === 1;
  if (isFirstBookingItemOnCart) {
    const isSendAddressToEcomCheckoutAndCartEnabled = experiments.enabled(
      ExperimentsConsts.SendAddressToEcomCheckoutAndCart,
    );
    const cartInfo: Cart = {
      id: addToCurrentCartResponse?.cart?.id,
      buyerInfo: {
        email: contactDetails.email ?? '',
      },
      contactInfo: {
        ...(isSendAddressToEcomCheckoutAndCartEnabled &&
        contactDetails.fullAddress &&
        country
          ? { address: { ...contactDetails.fullAddress, country } }
          : {}),
        contactDetails: formApi.mapContactDetails({ contactDetails }),
      },
    };
    const updateCartRequest: UpdateCartRequest = {
      cartInfo,
    };
    await formApi.updateCart(updateCartRequest);
  }
}

const shouldTakeNumberOfParticipantsFromForm = (serviceData: ServiceData) => {
  return mapToArray<SlotService>(serviceData.slotServices).every(
    (slotService) => {
      return !!slotService.dynamicPriceInfo?.serviceOptionsAndVariants;
    },
  );
};

const getTimezone = (serviceData: ServiceData) => {
  const sharedService = mapToArray<SlotService>(serviceData.slotServices)[0];
  return sharedService.nestedSlot?.timezone;
};

const getFormSchema = (serviceData: ServiceData) => {
  const sharedService = mapToArray<SlotService>(serviceData.slotServices)[0];
  return sharedService.service.formFromCatalog;
};

const isIndividualService = (serviceData: ServiceData) => {
  const sharedService = mapToArray<SlotService>(serviceData.slotServices)[0];
  return sharedService.service.type === ServiceType.INDIVIDUAL;
};
